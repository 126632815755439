import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import KeenSlider, { KeenSliderInstance } from 'keen-slider';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { TakerService } from 'src/app/pages/shared/taker.service';
import { Taker } from 'src/app/pages/taker/taker-profile/taker.model';

@Component({
  selector: 'app-experience-card',
  templateUrl: './experience-card.component.html',
  styleUrls: ['./experience-card.component.scss',
    '../../../../node_modules/keen-slider/keen-slider.min.css']
})
export class ExperienceCardComponent implements OnInit {

  @Input() experience;
  @Input() isDashboard = false;
  @Input() isFooterActive;

  @ViewChild("photosSlider") photosSlider: ElementRef<HTMLElement>;
  @ViewChild('autoShownModal', { static: false }) autoShownModal: ModalDirective;

  currentSlide: number = 0
  dotHelper: Array<Number> = []
  slider: KeenSliderInstance = null


  takerExperiences = [];
  isAlertShown = false;
  shownDays = [
    { value: 0, text: 'Todos los días' },
    { value: 1, text: 'Lun' },
    { value: 2, text: 'Mar' },
    { value: 3, text: 'Mié' },
    { value: 4, text: 'Jue' },
    { value: 5, text: 'Vie' },
    { value: 6, text: 'Sáb' },
    { value: 7, text: 'Dom' },
  ];

  constructor(
    public translate: TranslateService,
    private takerService: TakerService,
    private el: ElementRef,
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
      setTimeout(() => {
        this.slider = new KeenSlider(this.photosSlider.nativeElement, {
          initial: this.currentSlide,
          slideChanged: (s) => {
            this.currentSlide = s.track.details.rel
          },
        })
        this.dotHelper = [
          ...Array(this.slider.track.details.slides.length).keys(),
        ]
      });
  }

  ngOnDestroy() {
    if (this.slider) this.slider.destroy()
  }


  getUrl(isCivitatis, image) {
    // return !isCivitatis ? 'https://dev.venntur.com/assets/experience/files/' + image : image;
    // return !isCivitatis ? 'http://localhost:4200/assets/experience/files/' + image : image;
    return !isCivitatis ? 'https://venntur.com/assets/experience/files/' + image : image;
  }

  clickFavorite(event, experienceId) {
    if (this.takerService.getId()) {
      this.takerExperiences.includes(experienceId) ?
        this.takerExperiences.splice(this.takerExperiences.indexOf(experienceId), 1) : this.takerExperiences.push(experienceId);
      const taker = {
        favoriteExperiences: this.takerExperiences
      };
      const editedTaker = new Taker(taker);
      this.takerService.putTaker(this.takerService.getId(), editedTaker).subscribe();
    } else {
      this.isAlertShown = true;
    }
  }

  shareExperience(experience) {
    var enlace = 'https://venntur.com/product/' + experience.slug;
    var mensaje = this.translate.instant('¡Mira esta experiencia en Venntur!');

    mensaje = encodeURIComponent(mensaje);
    enlace = encodeURIComponent(enlace);

    var enlaceWhatsApp = 'https://wa.me/?text=' + mensaje + '%20' + enlace;

    window.open(enlaceWhatsApp);

  }

  setCategoryTitle(experience) {
    return experience.categories.map(obj => obj.name).join(' / ')
  }

  setLanguagesTitle(experience) {
    return experience.languages.map(obj => obj.name).join(' , ')
  }

  convertTimeToCustomFormat(inputTime: string): string {
    let formattedText;
    const [hours, minutes] = inputTime.split(':').map(part => parseInt(part, 10));

    if (minutes != 0) {
      formattedText = hours + ' h y ' + minutes + ' min'
    } else {
      formattedText = hours + ' h'
    }

    return formattedText;
  }

  hideModal(): void {
    this.autoShownModal.hide();
  }

  onHidden(): void {
    this.isAlertShown = false;
  }

}

