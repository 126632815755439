import { Component, OnInit, TemplateRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Maker } from './maker.model';
import { HttpEventType } from '@angular/common/http';
import { MediaMakerService } from './media-maker';
import { MakerService } from '../../shared/maker.service';
import { CustomValidators, errorMessages, regExps } from '../../shared/custom-validators';
import { AuthService } from '../../shared/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { Tag } from '../../../shared/tags/tag.model';
import { TagsService } from '../../../shared/tags/tags.service';
import { User } from '../../../shared/users/user.model';
import { UsersService } from '../../../shared/users/users.service';
import { LocationService } from 'src/app/shared/locations/location.service';
import { CountryService } from 'src/app/shared/locations/country.service';
import { ExperiencesService } from '../../experiences/experiences/experiences.service';
import { ReplaySubject } from 'rxjs';
import { Location } from '../../../shared/locations/location.model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalAlertComponent } from 'src/app/shared/modal-alert/modal-alert.component';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
//import file from angular http

declare const Stripe: any;

const FRONT_URL = environment.frontUrl;
const PUBLIC_KEY = environment.stripe.publicKey;
const BASIC_PRICE = environment.stripe.basicPrice;

@Component({
  selector: 'app-maker',
  styleUrls: ['./maker.component.scss'],
  templateUrl: './maker.component.html',

})
export class MakerComponent implements OnInit {
  public Editor = ClassicEditor;
  contactForm: UntypedFormGroup;
  makerForm: UntypedFormGroup;
  planForm: UntypedFormGroup;
  maker: Maker;
  title = 'Perfil maker';
  documentTypes = ['DNI', 'NIE', 'CIF'];
  documentTypeSelected = null;
  selectedFile: File;
  selectedFiles = [];
  uploadedFiles = [];

  selectedPdfFile: File;
  selectedPdfFiles = [];
  uploadedPdfFiles = [];
  pdfFiles: any;

  validPolizaRRCC = false;
  validReciboRRCC = false;
  validLicenciaActividad = false;
  validPlantillaContratoColaboracion = false;
  validCatalogos = false;

  namePolizaRRCC = '';
  nameReciboRRCC = '';
  nameLicenciaActividad = '';
  namePlantillaContratoColaboracion = '';
  nameCatalogos = '';

  image = null;
  contactImage = null;
  images = [];
  logoImage = null;
  headerImage = null;
  uploadingProgressing = false;
  uploadComplete = false;
  serverResponse: any;
  alert = { message: null, type: null };
  errors = errorMessages;
  uploadProgress = 0;
  makerId = null;
  phraseMaxChar = 160;
  descriptionMaxChar = 1200;
  plan = null;
  invoices = [];
  tags: Tag[] = [];
  tagsSelected = [];
  mediaMakersStaff = [];
  formLanguage = this.translate.getDefaultLang();
  columns = [];
  rows = [];
  plans = [];
  planSelected = null;
  public locationList: ReplaySubject<Location[]> = new ReplaySubject<Location[]>(1);
  countryList = [];
  editorTemplateStaticData = '';
  editorTemplate: any;

  locations: Location[] = [];

  makerExperiences: any;
  totalExperiences: any;
  publishedExperiences = 0;

  showTab = 'private';
  nextPayment = null;

  isInfoValid = false;
  isDirectoryValid = false;
  isSheetValid = false;
  isPinValid = false;
  pdf: any;

  productsSelected = 3;
  regulation = 'monthly'
  modalRef: BsModalRef;

  bookingEngine = '';

  dialogRef: BsModalRef;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private experiencesService: ExperiencesService,
    private makerService: MakerService,
    private userService: UsersService,
    private tagService: TagsService,
    private mediaMakerService: MediaMakerService,
    private locationService: LocationService,
    private countryService: CountryService,
    public translate: TranslateService,
    public dialog: BsModalService
  ) {
    this.makerService.getPlans().subscribe(plans => {
      this.plans = plans['hydra:member'];
    });
    /* this.locationService.getLocations().subscribe(locations => {
      this.locationList = locations['hydra:member'];
    }); */
    this.countryService.getCountries().subscribe(countries => {
      this.countryList = countries;
    });
    if (this.makerService.getId()) {
      this.title = 'Editar perfil maker';
      this.makerId = '/makers/' + this.makerService.getId();
    }
    this.createForm();
  }

  ngOnInit(): void {
    if (this.makerId) {
      setTimeout(() => {
        /* this.tagService.getTags(this.translate.getDefaultLang()).subscribe(tags => {
          this.tags = tags['hydra:member'];
        }); */
        this.editMaker(this.makerId);
      }, 1000);
    }

    this.makerForm.valueChanges.subscribe(changes => {
      this.checkInfoContent();
      this.checkDirectoryContent();
      this.checkSheetContent();
      this.checkPinContent();
      // this.checkWebSheetContent();
    });

    this.tagService.getTags(this.translate.getDefaultLang()).subscribe(tags => {
      this.tags = tags['hydra:member'];
    });

    this.activatedRoute.params.subscribe(params => {
      if (this.router.routerState.snapshot.url.split('/').pop() === "basico") {
        this.enableBasicFields();
      }
      setTimeout(() => {
        if (params['plan']) {
          this.planForm.get('plan').setValue(params['plan'].toLowerCase());
          this.plan = params['plan'];
        } else {
          this.planForm.get('plan').setValue('Plan Free - de 1 a 3 productos en directorio');
          this.plan = 'Plan Free - de 1 a 3 productos en directorio';
        }
      }, 1000);
    });

    this.experiencesService.getAllExperiencesByMakerId(this.makerService.getId()).subscribe(((resp: any) => {
      this.makerExperiences = resp['hydra:member'];
      this.totalExperiences = this.makerExperiences.length;

      this.makerExperiences.forEach(experience => {
        if (experience.isActive) {
          this.publishedExperiences++
        }
      });
    }))
  }

  createForm() {
    this.makerForm = this.formBuilder.group({
      name: ['',
        Validators.required
      ],
      companyName: ['',
        Validators.required],
      documentType: [null,
        Validators.required
      ],
      document: [null, [
        Validators.required,
        // CustomValidators.dniOrNieValidator
      ]],
      contactName: ['', [
        Validators.required,
      ]],
      contactLastname: ['', [
        Validators.required,
      ]],
      contactPhone: ['', [
        Validators.required,
      ]],
      contactEmail: ['', [
        Validators.required,
      ]],
      mediaMakers: [[],
      Validators.required
      ],
      mediaMakersHeader: [[], Validators.required
      ],
      mediaMakersLogo: [[]
      ],
      mediaMakersStaff: [[]
      ],
      phrase: ['', Validators.required],
      address: ['',
        Validators.required],
      location: [null,
        Validators.required],
      country: [null,
        Validators.required],
      postalCode: [null, [
          Validators.required,
          Validators.pattern('^[0-9]{5}$')  // Validates exactly 5 digits
        ]],
      phone: ['', [
        Validators.required,
        Validators.pattern(regExps.phone)
      ]],
      whatsapp: ['', [
        Validators.pattern(regExps.phone)
      ]],
      email: ['', [
        Validators.required,
        Validators.email
      ]],
      web: ['', [
        Validators.required,
        Validators.pattern(regExps.url)
      ]
      ],
      sustainableSeal: [false],
      user: ['/users/' + this.authService.getUserId()],
      tripadvisor: [''],
      description: [''],
      founders: [''],
      director: [''],
      operations: [''],
      technology: [''],
      customerId: [''],
      instagram: [''],
      twitter: [''],
      linkedIn: [''],
      facebook: [''],
      tags: [['/tags/10']],
      slug: [''],
      bookingEmail: [''],
      holdedId: [''],
      googleSites: [''],
      googleMaps: [''],
      phone2: [''],
      tripadvisorApiWidgetReview: [''],
      bookingEngine: [''],
      bookingEngineWidget: [''],
      polizaRRCC: [''],
      reciboRRCC: [''],
      licenciaActividad: [''],
      plantillaContratoColaboracion: [''],
      catalogos: [''],
      administrationEmail: [''],
      reservationConditions: [''],
      paymentConditions: [''],
      cancelationConditions: [''],
      idFareharbor: [''],
    });
    this.contactForm = this.formBuilder.group({
      mediaContacts: [[]],
      name: ['',
        Validators.required
      ],
      position: ['',
        Validators.required
      ],
      email: ['',
        Validators.required
      ],
      phone: ['',
        Validators.required
      ]
    });
    this.planForm = this.formBuilder.group({
      plan: ['']
    });
  }

  async createMaker() {
    this.makerForm.markAllAsTouched();
    // if (this.makerForm.invalid) {
    //   this.showErrors(this.makerForm);
    //   return;
    // }



    var slug = this.makerForm.get('name').value;

    console.log("Slug sin limpiar:");
    console.log(slug);

    slug = await this.convertSlug(slug);

    console.log("Slug FINAL:");
    console.log(slug);

    var postalCodeString = this.makerForm.get('postalCode').value.toString();


    const maker = {
      name: this.makerForm.get('name').value,
      companyName: this.makerForm.get('companyName').value,
      documentType: this.makerForm.get('documentType').value,
      document: this.makerForm.get('document').value,
      contactName: this.makerForm.get('contactName').value,
      contactLastname: this.makerForm.get('contactLastname').value,
      bookingEmail: this.makerForm.get('contactEmail').value,
      mediaMakers: this.makerForm.get('mediaMakers').value,
      mediaMakersHeader: this.makerForm.get('mediaMakersHeader').value,
      // mediaMakersLogo: this.makerForm.get('mediaMakersLogo').value,
      mediaMakersStaff: this.makerForm.get('mediaMakersStaff').value,
      phrase: this.makerForm.get('phrase').value,
      phone: this.makerForm.get('phone').value,
      whatsapp: this.makerForm.get('whatsapp').value,
      email: this.makerForm.get('email').value,
      web: this.makerForm.get('web').value,
      sustainableSeal: this.makerForm.get('sustainableSeal').value,
      user: this.makerForm.get('user').value,
      tripadvisor: this.makerForm.get('tripadvisor').value,
      description: this.makerForm.get('description').value,
      founders: this.makerForm.get('founders').value,
      director: this.makerForm.get('director').value,
      operations: this.makerForm.get('operations').value,
      technology: this.makerForm.get('technology').value,
      customerId: this.makerForm.get('customerId').value,
      instagram: this.makerForm.get('instagram').value,
      twitter: this.makerForm.get('twitter').value,
      linkedIn: this.makerForm.get('linkedIn').value,
      facebook: this.makerForm.get('facebook').value,
      tags: this.makerForm.get('tags').value,
      slug: slug,
      address: this.makerForm.get('address').value,
      postalCode: postalCodeString,
      location: this.makerForm.get('location').value,
      country: this.makerForm.get('country').value,
      holdedId: this.makerForm.get('holdedId').value,
      googleSites: this.makerForm.get('googleSites').value,
      googleMaps: this.makerForm.get('googleMaps').value,
      phone2: this.makerForm.get('phone2').value,
      tripadvisorApiWidgetReview: this.makerForm.get('tripadvisorApiWidgetReview').value,
      bookingEngine: this.makerForm.get('bookingEngine').value,
      bookingEngineWidget: this.makerForm.get('bookingEngineWidget').value,
      directoryCompleted: this.isDirectoryValid,
      sheetCompleted: this.isSheetValid,
      pinCompleted: this.isPinValid,
      administrationEmail: this.makerForm.get('administrationEmail').value,
      reservationConditions: this.makerForm.get('reservationConditions').value,
      paymentConditions: this.makerForm.get('paymentConditions').value,
      cancelationConditions: this.makerForm.get('cancelationConditions').value,
      idFareharbor: this.makerForm.get('idFareharbor').value,
    };
    if (this.maker) {
      this.createMakerPlan(false);
    } else {
      this.makerService.postMaker(maker).subscribe(() => {
        // this.alert.type = 1;
        // this.alert.message = this.translate.instant('Maker creado correctamente');
        // setTimeout(() => {
        //   this.alert = { message: null, type: null };
        // }, 5000);
        const roles = this.authService.roles();
        if (!roles.includes('ROLE_MAKER')) {
          roles.push('ROLE_MAKER');
        }
        const editedUser = new User({ roles: roles });
        this.userService.putUser(this.authService.getUserId(), editedUser).subscribe(currentUser => {
          localStorage.setItem('user', JSON.stringify(currentUser));
        });
        this.makerService.getMakerByUserId(this.authService.getUserId()).subscribe(currentMaker => {
          this.maker = currentMaker;
          this.makerId = currentMaker.id;
          localStorage.setItem('maker', JSON.stringify({ id: currentMaker.id, name: currentMaker.name }));
          this.createMakerPlan(false);
        });
        this.openAlert('Maker creado!', 'w-300', true)
      }, (error) => {
        // this.alert.message = error.error.code + '- ' + error.error.message;
        // this.alert.type = 2;
        // setTimeout(() => {
        //   this.alert = { message: null, type: null };
        // }, 5000);
        this.openAlert('Ha habido un error!', 'w-300', false)
      });
    }
  }

  editMaker(makerId) {
    this.makerService.getMaker(makerId, this.translate.getDefaultLang()).subscribe((maker: Maker) => {
      this.maker = maker;
      this.makerForm.get('name').setValue(maker.name);
      this.makerForm.get('email').setValue(maker.email);
      this.makerForm.get('documentType').setValue(maker.documentType);
      this.makerForm.get('document').setValue(maker.document);
      this.makerForm.get('phrase').setValue(maker.phrase);
      this.makerForm.get('phone').setValue(maker.phone);
      this.makerForm.get('whatsapp').setValue(maker.whatsapp);
      this.makerForm.get('email').setValue(maker.email);
      this.makerForm.get('web').setValue(maker.web);
      this.makerForm.get('sustainableSeal').setValue(maker.sustainableSeal);
      this.makerForm.get('address').setValue(maker.address);
      this.makerForm.get('postalCode').setValue(maker.postalCode);
      this.makerForm.get('companyName').setValue(maker.companyName);
      this.makerForm.get('contactName').setValue(maker.contactName);
      this.makerForm.get('contactLastname').setValue(maker.contactLastname);
      this.makerForm.get('bookingEmail').setValue(maker.bookingEmail);
      this.makerForm.get('country').setValue(maker.country);
      this.makerForm.get('holdedId').setValue(maker.holdedId);
      this.makerForm.get('googleSites').setValue(maker.googleSites);
      this.makerForm.get('googleMaps').setValue(maker.googleMaps);
      this.makerForm.get('phone2').setValue(maker.phone2);
      this.makerForm.get('tripadvisorApiWidgetReview').setValue(maker.tripadvisorApiWidgetReview);
      this.makerForm.get('bookingEngine').setValue(maker.bookingEngine);
      this.bookingEngine = maker.bookingEngine;
      this.makerForm.get('bookingEngineWidget').setValue(maker.bookingEngineWidget);
      this.makerForm.get('administrationEmail').setValue(maker.administrationEmail);
      this.makerForm.get('reservationConditions').setValue(maker.reservationConditions);
      this.makerForm.get('paymentConditions').setValue(maker.paymentConditions);
      this.makerForm.get('cancelationConditions').setValue(maker.cancelationConditions);
      this.makerForm.get('idFareharbor').setValue(maker.idFareharbor);


      if (maker.country) {
        this.loadLocations(maker.country);
      }

      if (maker.mediaMakersDocs.length > 0) {
        console.log("Hay documentos");
        console.log(maker.mediaMakersDocs);
        this.pdfFiles = maker.mediaMakersDocs;

        maker.mediaMakersDocs.forEach(pdf => {

          this.uploadedPdfFiles.push({
            id: pdf['@id'],
            url: '/assets/maker/pdf/files/' + pdf.contentUrl,
            name: pdf.name ? pdf.name : pdf.contentUrl,
            typeOf: pdf.typeOf
          });

        });

        if (this.uploadedPdfFiles.length > 0) {

          this.uploadedPdfFiles.forEach(pdf => {
            this.selectedPdfFiles.push(pdf);

            switch (pdf.typeOf) {
              case 'Poliza RRCC':
                this.validPolizaRRCC = true;
                this.namePolizaRRCC = pdf.name;
                break;
              case 'Recibo RRCC':
                this.validReciboRRCC = true;
                this.nameReciboRRCC = pdf.name;
                break;
              case 'Licencia de actividad':
                this.validLicenciaActividad = true;
                this.nameLicenciaActividad = pdf.name;
                break;
              case 'Plantilla de contrato de colaboración':
                this.validPlantillaContratoColaboracion = true;
                this.namePlantillaContratoColaboracion = pdf.name;
                break;
              case 'Catalogos':
                this.validCatalogos = true;
                this.nameCatalogos = pdf.name;
                break;

            }


            //this.switchDocsValues(pdf.typeOf, pdf);


          });


        }


      }

      this.makerForm.get('location').setValue(maker.location ? maker.location['@id'] : null);

      if (maker.mediaMakers.length > 0) {
        this.makerForm.get('mediaMakers').setValue([maker.mediaMakers[0]['@id']]);
        this.image = '/assets/maker/files/' + maker.mediaMakers[0]['contentUrl'];
      }
      if (maker['makerPlan'] && maker['holdedId']) {
        if (maker['makerPlan']['plan']['name'] === "Básico") {
          this.enableBasicFields();
        }
        this.makerService.getInvoices(this.maker['holdedId']).subscribe((result) => {
          this.invoices = result['invoices'];
        });

        this.makerService.getStripeCustomer(this.maker['makerPlan']['stripeCustomerId']).subscribe((resp: any) => {
          this.nextPayment = resp['stripeCustomer'];
        })
      }

      this.makerForm.get('tripadvisor').setValue(maker.tripadvisor);
      this.makerForm.get('description').setValue(maker.description);
      this.makerForm.get('founders').setValue(maker.founders);
      this.makerForm.get('director').setValue(maker.director);
      this.makerForm.get('operations').setValue(maker.operations);
      this.makerForm.get('technology').setValue(maker.technology);
      this.makerForm.get('customerId').setValue(maker.customerId);
      this.makerForm.get('instagram').setValue(maker.instagram);
      this.makerForm.get('twitter').setValue(maker.twitter);
      this.makerForm.get('linkedIn').setValue(maker.linkedIn);
      this.makerForm.get('facebook').setValue(maker.facebook);

      const tags = [];
      if (maker.tags) {
        maker.tags.forEach(tag => {
          tags.push(tag);
        });
        this.makerForm.get('tags').setValue(tags);
      }
      // this.makerForm.get('tags').setValue(maker.tags);
      if (maker.mediaMakersHeader.length > 0) {
        this.makerForm.get('mediaMakersHeader').setValue([maker.mediaMakersHeader[0]['@id']]);
        this.headerImage = '/assets/makerHeader/files/' + maker.mediaMakersHeader[0]['contentUrl'];
      }
      // if (maker.mediaMakersLogo.length > 0) {
      //   this.makerForm.get('mediaMakersLogo').setValue([maker.mediaMakersLogo[0]['@id']]);
      //   this.logoImage = '/assets/makerLogo/files/' + maker.mediaMakersLogo[0]['contentUrl'];
      // }
      maker.mediaMakersStaff.forEach(mediaMakerStaff => {
        if (!this.mediaMakersStaff.some(e => e.id === mediaMakerStaff['@id'])) {
          this.mediaMakersStaff.push({ url: '/assets/makerStaff/files/' + mediaMakerStaff['contentUrl'], id: mediaMakerStaff['@id'] });
          this.uploadedFiles.push(mediaMakerStaff['@id']);
        } else if (this.mediaMakersStaff.length <= 0) {
          this.mediaMakersStaff.push({ url: '/assets/makerStaff/files/' + mediaMakerStaff['contentUrl'], id: mediaMakerStaff['@id'] });
          this.uploadedFiles.push(mediaMakerStaff['@id']);
        }
      });
      this.makerForm.get('mediaMakersStaff').setValue(this.uploadedFiles);
      this.rows = maker['contacts'];
      if (this.makerForm.get('documentType').value === 'Otros') {
        this.makerForm.get('document').setErrors(null);
        this.makerForm.get('document').clearValidators();
        this.makerForm.get('document').setValidators([Validators.required]);
        this.makerForm.updateValueAndValidity();
      }
    });
  }

  async updateMaker() {
    this.makerForm.markAllAsTouched();
    // if (this.makerForm.invalid) {
    //   this.showErrors(this.makerForm);
    //   return;
    // }


    if (this.selectedPdfFiles.length > 0) {

      //this.uploadPdfFiles();

    }





    var slug = this.makerForm.get('name').value;

    console.log("Slug sin limpiar:");
    console.log(slug);

    slug = await this.convertSlug(slug);

    console.log("Slug FINAL:");
    console.log(slug);

    var postalCodeString = this.makerForm.get('postalCode').value ? this.makerForm.get('postalCode').value.toString() : null;


    const maker = {
      name: this.makerForm.get('name').value,
      documentType: this.makerForm.get('documentType').value,
      document: this.makerForm.get('document').value,
      mediaMakers: this.makerForm.get('mediaMakers').value,
      mediaMakersHeader: this.makerForm.get('mediaMakersHeader').value,
      // mediaMakersLogo: this.makerForm.get('mediaMakersLogo').value,
      mediaMakersStaff: this.makerForm.get('mediaMakersStaff').value,
      phrase: this.makerForm.get('phrase').value,
      phone: this.makerForm.get('phone').value,
      whatsapp: this.makerForm.get('whatsapp').value,
      email: this.makerForm.get('email').value,
      web: this.makerForm.get('web').value,
      sustainableSeal: this.makerForm.get('sustainableSeal').value,
      tripadvisor: this.makerForm.get('tripadvisor').value,
      description: this.makerForm.get('description').value,
      founders: this.makerForm.get('founders').value,
      director: this.makerForm.get('director').value,
      operations: this.makerForm.get('operations').value,
      technology: this.makerForm.get('technology').value,
      customerId: this.makerForm.get('customerId').value,
      instagram: this.makerForm.get('instagram').value,
      twitter: this.makerForm.get('twitter').value,
      linkedIn: this.makerForm.get('linkedIn').value,
      facebook: this.makerForm.get('facebook').value,
      tags: this.makerForm.get('tags').value,
      slug: slug,
      address: this.makerForm.get('address').value,
      postalCode: postalCodeString,
      location: this.makerForm.get('location').value,
      country: this.makerForm.get('country').value,
      companyName: this.makerForm.get('companyName').value,
      contactName: this.makerForm.get('contactName').value,
      contactLastname: this.makerForm.get('contactLastname').value,
      bookingEmail: this.makerForm.get('bookingEmail').value,
      holdedId: this.makerForm.get('holdedId').value,
      googleSites: this.makerForm.get('googleSites').value,
      googleMaps: this.makerForm.get('googleMaps').value,
      phone2: this.makerForm.get('phone2').value,
      tripadvisorApiWidgetReview: this.makerForm.get('tripadvisorApiWidgetReview').value,
      bookingEngine: this.makerForm.get('bookingEngine').value,
      bookingEngineWidget: this.makerForm.get('bookingEngineWidget').value,
      directoryCompleted: this.isDirectoryValid,
      sheetCompleted: this.isSheetValid,
      pinCompleted: this.isPinValid,
      administrationEmail: this.makerForm.get('administrationEmail').value,
      reservationConditions: this.makerForm.get('reservationConditions').value,
      paymentConditions: this.makerForm.get('paymentConditions').value,
      cancelationConditions: this.makerForm.get('cancelationConditions').value,
      idFareharbor: this.makerForm.get('idFareharbor').value,

    };
    const editedMaker = new Maker(maker);
    this.makerService.putMaker(this.maker.id, editedMaker, this.translate.getDefaultLang())
      .subscribe(
        (result) => {
          // this.alert.type = 1;
          // this.alert.message = this.translate.instant('Maker guardado correctamente');
          // setTimeout(() => {
          //   this.alert = { message: null, type: null };
          // }, 5000);
          this.openAlert('Maker guardado!', 'w-300', true)
        },
        error => {
          // this.alert.message = error.error.code + '- ' + error.error.message;
          // this.alert.type = 2;
          // setTimeout(() => {
          //   this.alert = { message: null, type: null };
          // }, 5000);
          this.openAlert('Ha habido un error!', 'w-300', false)
        },
        () => console.log('complete'));
  }

  createContact() {
    if (this.contactForm.invalid) {
      this.showErrors(this.contactForm);
      return;
    }
    const contact = {
      mediaContacts: this.contactForm.get('mediaContacts').value,
      name: this.contactForm.get('name').value,
      position: this.contactForm.get('position').value,
      email: this.contactForm.get('email').value,
      phone: this.contactForm.get('phone').value,
      maker: this.maker['@id']
    };
    this.makerService.postContact(contact).subscribe(() => {
      this.alert.type = 1;
      this.alert.message = this.translate.instant('Contacto creado correctamente');
      setTimeout(() => {
        this.alert = { message: null, type: null };
      }, 5000);
    }, (error) => {
      this.alert.message = error.error.code + '- ' + error.error.message;
      this.alert.type = 2;
      setTimeout(() => {
        this.alert = { message: null, type: null };
      }, 5000);
    });
  }

  // updateContact() {
  //   if (this.contactForm.invalid) {
  //     this.showErrors(this.contactForm);
  //     return;
  //   }
  //   const contact = {
  //     mediaContact: this.contactForm.get('mediaContact').value,
  //     name: this.contactForm.get('name').value,
  //     position: this.contactForm.get('position').value,
  //     email: this.contactForm.get('email').value,
  //     phone: this.contactForm.get('phone').value
  //   };
  //   const editedContact = new Contact(contact);
  //   this.makerService.putContact(this.contact.id, editedContact, this.formLanguage)
  //     .subscribe(
  //       (result) => {
  //         this.alert.type = 1;
  //         this.alert.message = this.translate.instant('Contacto guardado correctamente');
  //         setTimeout(() => {
  //           this.alert = {message: null, type: null};
  //         }, 5000);
  //       },
  //       error => {
  //         this.alert.message = error.error.code + '- ' + error.error.message;
  //         this.alert.type = 2;
  //         setTimeout(() => {this.alert = {message: null, type: null};
  //         }, 5000);
  //       },
  //       () => console.log('complete'));
  // }

  onFileChanged(event) {
    if (event.target.files[0].type === 'image/jpeg' || event.target.files[0].type === 'image/png') {
      this.selectedFile = event.target.files[0];
      const reader = new FileReader();

      reader.onload = (event: any) => {
        this.image = event.target.result;
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  }

  enableBasicFields() {
    this.makerForm.get('phrase').enable();
    this.makerForm.get('tripadvisor').enable();
    this.makerForm.get('description').enable();
    // this.makerForm.get('tripadvisor').setValidators([Validators.required]);
    // this.makerForm.get('description').setValidators([Validators.required]);
    this.makerForm.get('founders').enable();
    this.makerForm.get('director').enable();
    // this.makerForm.get('founders').setValidators([Validators.required]);
    // this.makerForm.get('director').setValidators([Validators.required]);
    this.makerForm.get('operations').enable();
    this.makerForm.get('technology').enable();
    // this.makerForm.get('operations').setValidators([Validators.required]);
    // this.makerForm.get('technology').setValidators([Validators.required]);
    this.makerForm.get('customerId').enable();
    this.makerForm.get('instagram').enable();
    // this.makerForm.get('customerId').setValidators([Validators.required]);
    // this.makerForm.get('instagram').setValidators([Validators.required]);
    this.makerForm.get('twitter').enable();
    this.makerForm.get('linkedIn').enable();
    // this.makerForm.get('twitter').setValidators([Validators.required]);
    // this.makerForm.get('linkedIn').setValidators([Validators.required]);
    this.makerForm.get('facebook').enable();
    // this.makerForm.get('facebook').setValidators([Validators.required]);
    this.makerForm.get('tags').enable();

    //this.makerForm.get('slug').enable();

    // this.makerForm.get('tags').setValidators([Validators.required]);
    this.makerForm.updateValueAndValidity();
  }

  onUpload() {
    this.mediaMakerService.mediaMakersUpload(
      this.selectedFile, '/media_makers').subscribe(result => {
        this.handleProgress(result);
      }, error => {
        this.alert.message = error.error.code + '- ' + error.error.message;
        this.alert.type = 2;
        setTimeout(() => {
          this.alert = { message: null, type: null };
        }, 5000);
      });
  }

  handleProgress(event) {
    if (event.type === HttpEventType.DownloadProgress) {
      this.uploadingProgressing = true;
      this.uploadProgress = Math.round(100 * event.loaded / event.total);
    }

    if (event.type === HttpEventType.UploadProgress) {
      this.uploadingProgressing = true;
      this.uploadProgress = Math.round(100 * event.loaded / event.total);
    }

    if (event.type === HttpEventType.Response) {
      this.uploadComplete = true;
      this.uploadingProgressing = false;
      this.serverResponse = event.body;

      this.alert.type = 1;
      this.alert.message = this.translate.instant('Logo guardado correctamente');
      setTimeout(() => {
        this.alert = { message: null, type: null };
      }, 5000);

      this.makerForm.get('mediaMakers').setValue([event.body['@id']]);
    }
  }

  onUploadedImage(uploadedImage: object, formControlName: string) {
    if (formControlName === 'mediaMakersStaff') {
      this.mediaMakersStaff.push({
        url: uploadedImage['url'],
        id: uploadedImage['id']
      });
      this.uploadedFiles.push(uploadedImage['id']);
      this.makerForm.get(formControlName).patchValue(this.uploadedFiles);
    }
    if (formControlName === 'mediaMakers') {
      this.makerForm.get(formControlName).patchValue([uploadedImage['id']]);
      this.image = uploadedImage['url'];
    }
    if (formControlName === 'mediaMakersLogo') {
      this.makerForm.get(formControlName).patchValue([uploadedImage['id']]);
      this.logoImage = uploadedImage['url'];
    }
    if (formControlName === 'mediaMakersHeader') {
      this.makerForm.get(formControlName).patchValue([uploadedImage['id']]);
      this.headerImage = uploadedImage['url'];
    }
    if (formControlName === 'mediaContacts') {
      this.contactForm.get(formControlName).patchValue([uploadedImage['id']]);
      this.contactImage = uploadedImage['url'];
    }
  }

  showErrors(formGroup) {
    Object.keys(formGroup.controls).forEach(key => {
      formGroup.get(key).markAsTouched();
    });
  }

  showError(formValue) {
    // Handling documentType and document validation
    if (formValue === 'documentType') {
      if (this.makerForm.get('documentType').value === 'CIF') {
        this.makerForm.get('document').clearValidators();
        this.makerForm.get('document').setValidators([Validators.required, CustomValidators.isValidCif]);
        this.makerForm.updateValueAndValidity();
        this.makerForm.get('document').setErrors(CustomValidators.isValidCif(this.makerForm.get('document').value));
        this.makerForm.get('document').markAsTouched();
      } else {
        this.makerForm.get('document').clearValidators();
        this.makerForm.get('document').setValidators([Validators.required, CustomValidators.dniOrNieValidator]);
        this.makerForm.updateValueAndValidity();
        this.makerForm.get('document').setErrors(CustomValidators.dniOrNieValidator(this.makerForm.get('document')));
        this.makerForm.get('document').markAsTouched();
      }
    }
  
    // General validation for other fields (address, postalCode, description, etc.)
    if (formValue === 'address' || formValue === 'postalCode' || formValue === 'description') {
      this.makerForm.get(formValue).markAsTouched();
      this.makerForm.get(formValue).updateValueAndValidity();
    }
  
    // Return true if the form control is invalid and has been touched
    return this.makerForm.get(formValue).invalid && this.makerForm.get(formValue).touched;
  }
  

  showContactError(formValue) {
    return this.contactForm.get(formValue).invalid && this.contactForm.get(formValue).touched;
  }

  changeLanguage(language) {
    this.formLanguage = language;
    // this.translate.setDefaultLang(language);
    this.editMaker(this.makerId);
  }

  deleteMediaMakersStaff(image, uploaded) {
    if (uploaded) {
      this.mediaMakerService.deleteMediaMakers(image).subscribe(() => {
        const index = this.uploadedFiles.indexOf(image);
        this.uploadedFiles.splice(index, 1);
        this.mediaMakersStaff = this.mediaMakersStaff.filter((el) => el.id !== image);
        this.makerForm.get('mediaMakersStaff').setValue(this.uploadedFiles);
      }
      );
    } else {
      this.selectedFiles = this.selectedFiles.filter((el) => el.name !== image);
      this.images = this.images.filter((el) => el.name !== image);
    }
  }

  loadLocations(countryCode: string) {
    this.locationService.getLocationsFiltered({ country: countryCode }, this.translate.getDefaultLang()).subscribe(
      (locations) => {
        this.locations = locations;
        this.locationList.next(this.locations.slice());
      }
    );
  }

  createMakerPlan(isChangingPlan) {
    if (this.planForm.get('plan').value.toLowerCase() === 'Plan Free - de 1 a 3 productos en directorio' && !isChangingPlan) {
      this.makerService.postFreeMakerPlan({ price: 0, email: this.authService.getUserEmail() }).subscribe(() => {
        window.location.reload();
      });
      // this.router.navigateByUrl(this.translate.getDefaultLang() + '/maker/admin/perfil');
    } else {
      let planPrice = null;
      if (this.planForm.get('plan').value.toLowerCase() === 'pro-m-3' || isChangingPlan) {
        planPrice = BASIC_PRICE;
      } else if (this.planForm.get('plan').value === 'pro') {
        planPrice = '';
      }
      // @ts-ignore
      const stripe = Stripe(PUBLIC_KEY);
      stripe.redirectToCheckout({
        lineItems: [{ price: planPrice, quantity: 1 }],
        mode: 'subscription',
        successUrl: `${FRONT_URL}${this.translate.getDefaultLang()}/maker/admin/perfil`,
        cancelUrl: `${FRONT_URL}${this.translate.getDefaultLang()}`,
      })
        // tslint:disable-next-line:only-arrow-functions no-shadowed-variable
        .then(function (result) {
          if (result.error) {
            const displayError = document.getElementById('error-message');
            displayError.textContent = result.error.message;
          }
        });
    }
  }

  timestampToDate(timestamp) {
    // Create a new Date object with the Unix timestamp (in milliseconds)
    const date = new Date(timestamp * 1000);

    // Extract the day, month, and year
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero-based
    const year = date.getFullYear();

    // Format the date as 'dd/mm/yyyy'
    const formattedDate = `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;

    return formattedDate;
  }

  downloadPdf(fileNumber, invoiceId) {
    this.makerService.getInvoicePdf(invoiceId).subscribe((result) => {
      const binaryString = atob(result['invoices']['data']);
      
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }

      // Create a Blob from the binary data
      const blob = new Blob([bytes], { type: 'application/pdf' });

      // Create a URL for the Blob
      const url = URL.createObjectURL(blob);

      // Create a link element and trigger a download
      const link = document.createElement('a');
      link.href = url;
      link.download = fileNumber+'.pdf';
      link.click();

      // Clean up the URL object
      URL.revokeObjectURL(url);
    });
  }

  checkInfoContent() {
    let controlNames = ['companyName', 'documentType', 'document', 'contactName', 'contactLastname'];
    this.isInfoValid = false;
    for (let controlName of controlNames) {
      if (this.makerForm.get(controlName).valid) {
        this.isInfoValid = true;
      } else {
        this.isInfoValid = false;
        break;
      }
    };
  }

  checkDirectoryContent() {
    let controlNames = ['name', 'mediaMakers', 'mediaMakersHeader', 'phone', 'email', 'web'];
    this.isDirectoryValid = false;
    for (let controlName of controlNames) {
      if (this.makerForm.get(controlName).valid) {
        this.isDirectoryValid = true;
      } else {
        if (controlName == 'mediaMakers' && this.makerForm.get(controlName).value.length > 0) {
          this.isDirectoryValid = true;
        } else {
          this.isDirectoryValid = false;
        }

        break;
      }
    };
  }

  checkSheetContent() {
    let controlNames = ['mediaMakersHeader', 'description', 'address', 'location', 'country', 'postalCode'];
    this.isSheetValid = false;
    for (let controlName of controlNames) {
      if (this.makerForm.get(controlName).valid) {
        this.isSheetValid = true;
      } else {
        if (controlName == 'mediaMakersHeader' && this.makerForm.get(controlName).value.length > 0) {
          this.isSheetValid = true;
        } else {
          this.isSheetValid = false;
        }

        break;
      }
    };
  }

  checkPinContent() {

    //administrationEmail, phone2, bookingEngine, bookingEngineWidget, tripadvisor, linkedin, facebook, instagram, twitter, googleMaps, reservationConditions, paymentConditions, cancelationConditions



    this.isPinValid = false;

    if (this.maker) {


      var administrationEmail = this.makerForm.get('administrationEmail').value;
      var phone2 = this.makerForm.get('phone2').value;
      var bookingEngine = this.makerForm.get('bookingEngine').value;
      var bookingEngineWidget = this.makerForm.get('bookingEngineWidget').value;
      var tripadvisor = this.makerForm.get('tripadvisor').value;
      var linkedIn = this.makerForm.get('linkedIn').value;
      var facebook = this.makerForm.get('facebook').value;
      var instagram = this.makerForm.get('instagram').value;
      var twitter = this.makerForm.get('twitter').value;
      var reservationConditions = this.makerForm.get('reservationConditions').value;
      var paymentConditions = this.makerForm.get('paymentConditions').value;
      var cancelationConditions = this.makerForm.get('cancelationConditions').value;

      if (this.selectedPdfFiles.length == 5 && this.mediaMakersStaff.length > 0 && (administrationEmail != '' && administrationEmail != null)
        && (phone2 != '' && phone2 != null) && (bookingEngine != '' && bookingEngine != null) && (bookingEngineWidget != '' && bookingEngineWidget != null)
        && (tripadvisor != '' && tripadvisor != null) && (linkedIn != '' && linkedIn != null) && (facebook != '' && facebook != null)
        && (instagram != '' && instagram != null) && (twitter != '' && twitter != null) && (reservationConditions != '' && reservationConditions != null)
        && (paymentConditions != '' && paymentConditions != null) && (cancelationConditions != '' && cancelationConditions != null)) {

        this.isPinValid = true;
      } else {
        this.isPinValid = false;
      }

    }

  }

  async convertSlug(slug: string) {
    // Create a map of characters to replace
   /*  const charMap: { [key: string]: string } = {
      'ÀÁÂÃÄÅ': 'A', 'àáâãäå': 'a',
      'ÈÉÊË': 'E', 'èéêë': 'e',
      'ÍÌÎÏ': 'I', 'íìîï': 'i',
      'ÓÒÔÕÖ': 'O', 'óòôõö': 'o',
      'ÚÙÛÜ': 'U', 'úùûü': 'u',
      'Ñ': 'N', 'ñ': 'n',
      'Ç': 'C', 'ç': 'c',
      'Ý': 'Y', 'ÿý': 'y',
      '’‘“”«»': '', // Special quotation marks
      '—·/\\': '-', // Replace with hyphens
      '?¿!¡ºª°&<>;"\'.,:[]{}()~|%*@+': '', // Remove these characters
    };
  
    // Replace characters using the charMap
    for (const key in charMap) {
      slug = slug.replace(new RegExp(`[${key}]`, 'g'), charMap[key]);
    } */
  
    // Further cleanup: remove extra hyphens, trim hyphens, and convert to lowercase
    let slugCleared = slug.replace(/ /g, '-')   // Replace spaces with hyphen
                          .replace(/--+/g, '-') // Replace multiple hyphens with a single one
                          .replace(/^-|-$/g, '') // Trim hyphens at the start and end
                          .toLowerCase();
  
    // Check if the slug exists
    const slugExist = await this.verifySlugExists(slugCleared);
  
    // Append "-1" if the slug already exists and is not the current slug
    if (slugExist && this.maker.slug !== slugCleared) {
      slugCleared = `${slugCleared}-1`;
    }
  
    return slugCleared;
  }
  


  async verifySlugExists(slug: string) {

    var slugExist = false;

    var maker = await this.makerService.getMakerBySlug(slug).toPromise();

    maker = maker['hydra:member'];

    if (maker.length > 0 && maker) {

      slugExist = true;

      return slugExist;

    } else {

      slugExist = false;

      return slugExist;
    }

  }

  onFileSelected(event, type) {
    console.log(event);
    console.log(type);

    /* get the pdf file from the event input */
    const file = event.target.files[0];
    console.log(file);

    file.typeOf = type;

    if (file.type != "application/pdf" || !file) {
      return;
    }


    /* Switch: type:  */
    this.switchDocsValues(type, file);

    console.log(this.selectedPdfFiles);
    //si type está dentro de selectedPdfFiles.typeOf, borrarlo y sustituirlo por el nuevo
    if (this.selectedPdfFiles.length > 0) {
      for (var i = 0; i < this.selectedPdfFiles.length; i++) {
        if (this.selectedPdfFiles[i].typeOf == type) {

          //delete PDF
          this.mediaMakerService.deleteMediaMakers(this.selectedPdfFiles[i].id).subscribe(() => {
            console.log("PDF deleted");
            this.selectedPdfFiles.splice(i, 1);
          });


        }
      }

    }



    this.uploadPdfFile(file);

    console.log(this.selectedPdfFiles);

    this.checkPinContent();



  }

  uploadPdfFiles() {

    console.log(this.selectedPdfFiles);
    console.log(this.makerId);

    if (this.selectedPdfFiles.length > 0) {

      this.selectedPdfFiles.forEach(file => {

        var objectFile = {
          maker: this.makerId,
          typeOf: file.typeOf
        };
        console.log(file);
        console.log(objectFile);

        this.selectedPdfFile = file;

        this.mediaMakerService.mediaMakersUpload(file, '/media_makers_docs', objectFile
        ).subscribe(result => {

          this.selectedPdfFiles.push(result);

          console.log(result);
          this.handleProgress(result);

        }, error => {
          this.alert.message = error.error.code + '- ' + error.error.message;
          this.alert.type = 2;
          setTimeout(() => {
            this.alert = { message: null, type: null };
          }, 5000);

          console.log(error);



        });

      });
    }



    /*  for(var i = 0; i < this.selectedPdfFiles.length; i++){
 
       const formData = new FormData();
       formData.append('file', this.selectedPdfFiles[i], this.selectedPdfFiles[i].name);
 
       this.makerService.postPdfFile(formData).subscribe((result) => {
 
         console.log(result);
 
         //this.makerForm.get('mediaPdf').setValue([result['@id']]);
         this.pdf = FRONT_URL + result['contentUrl'];
 
         this.alert.type = 1;
         this.alert.message = this.translate.instant('Logo guardado correctamente');
         setTimeout(() => {
           this.alert = { message: null, type: null };
         }, 5000);
 
         console.log(this.pdf);
         
   
       });
 
     } */
  }

  uploadPdfFile(file) {

    var objectFile = {
      maker: this.makerId,
      typeOf: file.typeOf
    };
    console.log(file);
    console.log(objectFile);

    this.mediaMakerService.mediaMakersUpload(file, '/media_makers_docs', objectFile
    ).subscribe(result => {

      console.log(result.body);

      if (result.body) {

        result.body.id = result.body['@id'];

        this.selectedPdfFiles.push(result.body);

      }



    }, error => {
      this.alert.message = error.error.code + '- ' + error.error.message;
      this.alert.type = 2;
      setTimeout(() => {
        this.alert = { message: null, type: null };
      }, 5000);

      console.log(error);

    });


  }


  switchDocsValues(type, file) {


    switch (type) {
      case 'Poliza RRCC':

        console.log("polizaRRCC");
        //set class success-border
        document.getElementById("polizaRRCCLabel").classList.add("success-border");
        document.getElementById("polizaRRCCLabel").classList.remove("error-border");
        //set the text of the label to the name of the file
        document.getElementById("polizaRRCCLabel").innerHTML = '';
        document.getElementById("polizaRRCCLabel").innerHTML = file.name;



        break;
      case 'Recibo RRCC':
        console.log("reciboRRCC");
        //set class success-border
        document.getElementById("reciboRRCCLabel").classList.add("success-border");
        document.getElementById("reciboRRCCLabel").classList.remove("error-border");
        //set the text of the label to the name of the file
        document.getElementById("reciboRRCCLabel").innerHTML = '';
        document.getElementById("reciboRRCCLabel").innerHTML = file.name;



        break;
      case 'Licencia de actividad':
        console.log("licenciaActividad");
        //set class success-border
        document.getElementById("licenciaActividadLabel").classList.add("success-border");
        document.getElementById("licenciaActividadLabel").classList.remove("error-border");
        //set the text of the label to the name of the file
        document.getElementById("licenciaActividadLabel").innerHTML = '';
        document.getElementById("licenciaActividadLabel").innerHTML = file.name;

        break;
      case 'Plantilla de contrato de colaboración':
        console.log("plantillaContratoColaboracion");
        //set class success-border
        document.getElementById("plantillaContratoColaboracionLabel").classList.add("success-border");
        document.getElementById("plantillaContratoColaboracionLabel").classList.remove("error-border");
        //set the text of the label to the name of the file
        document.getElementById("plantillaContratoColaboracionLabel").innerHTML = '';
        document.getElementById("plantillaContratoColaboracionLabel").innerHTML = file.name;

        break;
      case 'Catalogos':
        console.log("catalogos");
        //set class success-border
        document.getElementById("catalogosLabel").classList.add("success-border");
        document.getElementById("catalogosLabel").classList.remove("error-border");
        //set the text of the label to the name of the file
        document.getElementById("catalogosLabel").innerHTML = '';
        document.getElementById("catalogosLabel").innerHTML = file.name;

        break;
      default:
        console.log("default");
        break;
    }


  }

  deletePdf(pdfId: any, pdf: any) {
    console.log(pdfId);
    console.log(pdf);

    if (pdfId) {

      this.mediaMakerService.deleteMediaDocs(pdfId).subscribe(() => {
        console.log("pdf deleted");

        //remove pdf from this.selectedPdfFiles
        for (var i = 0; i < this.selectedPdfFiles.length; i++) {
          if (this.selectedPdfFiles[i].id == pdfId) {
            this.selectedPdfFiles.splice(i, 1);

            switch (pdf.typeOf) {
              case 'Poliza RRCC':
                this.validPolizaRRCC = false;
                break;
              case 'Recibo RRCC':
                this.validReciboRRCC = false;
                break;
              case 'Licencia de actividad':
                this.validLicenciaActividad = false;
                break;
              case 'Plantilla de contrato de colaboración':
                this.validPlantillaContratoColaboracion = false;
                break;
              case 'Catalogos':
                this.validCatalogos = false;
                break;

            }


          }
        }

        this.checkPinContent();


        /* this.alert.type = 1;
        this.alert.message = this.translate.instant('Logo guardado correctamente');
        setTimeout(() => {
          this.alert = { message: null, type: null };
        }, 5000); */
      }
      );

    } else {

      //remove pdf from this.selectedPdfFiles
      for (var i = 0; i < this.selectedPdfFiles.length; i++) {
        if (this.selectedPdfFiles[i].name == pdf.name) {
          this.selectedPdfFiles.splice(i, 1);

          switch (pdf.typeOf) {
            case 'Poliza RRCC':
              this.validPolizaRRCC = false;
              break;
            case 'Recibo RRCC':
              this.validReciboRRCC = false;
              break;
            case 'Licencia de actividad':
              this.validLicenciaActividad = false;
              break;
            case 'Plantilla de contrato de colaboración':
              this.validPlantillaContratoColaboracion = false;
              break;
            case 'Catalogos':
              this.validCatalogos = false;
              break;

          }

        }
      }

    }

  }

  validatePostalCode(event) {
    let postalCode = event.target.value;
  
    postalCode = postalCode.replace(/[a-zA-Z]/g, "");
    postalCode = postalCode.replace(/ /g, "");
    postalCode = postalCode.replace(/-/g, "");
  
    this.makerForm.get('postalCode').setValue(postalCode, { emitEvent: true });
    this.makerForm.get('postalCode').markAsTouched();  // Mark as touched
    this.makerForm.get('postalCode').updateValueAndValidity();  // Revalidate the field
  }

  openAlert(modalText: string, className: string, status: boolean) {
    let iconClass: string;
    if (status) {
      iconClass = 'vi-check';
    } else {
      iconClass = 'vi-sad';
    }
    const modalConfig = {
      class: className,
      initialState: {
        alertMessage: this.translate.instant(
          modalText
        ),
        iconClass,
        status
      }
    }
    this.dialogRef = this.dialog.show(ModalAlertComponent, modalConfig);
    setTimeout(() => {
      this.dialogRef.hide();
    }, 2500);
  }

  openUpgradePlanModal(template: TemplateRef<any>, config: any) {
    this.modalRef = this.dialog.show(template, config);
  }

  pay() {
    let MONTHLY = [0, 0, 0, 'price_1Nb5Z7ECh0SazpvMUadAJcuk', 'price_1Nb5DLECh0SazpvMmdT3Huk9', 'price_1NdbGeECh0SazpvMGmc9xD7a', 'price_1NdbM1ECh0SazpvMFzbk2CqI', 'price_1NdbO1ECh0SazpvML8uKSyOU', 'price_1NibmlECh0SazpvMdeHZqxM8', 'price_1NicLJECh0SazpvMTdJipfSE', 'price_1NicMYECh0SazpvMVi6KGM53'];
    let ANUALLY = [0, 0, 0, 'price_1NdZ9qECh0SazpvMKrUExRSr', 'price_1Nb5IKECh0SazpvMiGYN6f0s', 'price_1NdbIJECh0SazpvMm91UniiJ', 'price_1NdbN4ECh0SazpvMMlYi3cTt', 'price_1NicISECh0SazpvMWNWj59OP', 'price_1NicKSECh0SazpvMSojrXJkh', 'price_1NicLrECh0SazpvMZHNNLzyY', 'price_1O5nktECh0SazpvMZKbRbpTh'];

    // PUBLIC_KEY = "pk_test_PpbS40l0Y5lUsMN5YzpGlDZj00duWmRb3T";
    let PUBLIC_KEY = "pk_live_oJ6GN2TgQ5d6LvUiigRMQU6L003eXQBbMH";

    const stripe = Stripe(PUBLIC_KEY);
    stripe.redirectToCheckout({
      lineItems: [{ price: this.regulation == 'monthly' ? MONTHLY[this.productsSelected] : ANUALLY[this.productsSelected], quantity: 1 }],
      mode: 'subscription',
      successUrl: `https://venntur.com/es/maker/admin/perfil`,
      cancelUrl: `https://venntur.com/es/maker/admin/perfil`,
    })
      .then(function (result) {
        if (result.error) {
          const displayError = document.getElementById('error-message');
          displayError.textContent = result.error.message;
        }
      });
  };
}
