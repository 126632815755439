<div class="container" *ngIf="experience">
    <div class="row mt-5">
        <div class="col">
            <strong style="font-size: 26px;">
                {{experience.name}}
            </strong>
            <div class="d-flex justify-content-between">
                <div>
                    <strong class="mr-3" *ngFor="let category of experience.categories; let last = last">
                        {{category.name}}<ng-container *ngIf="!last">,</ng-container>
                    </strong>
                    <span>
                        |
                    </span>
                    <span class="vi-pin"></span>
                    <span>
                        {{experience.location.name}}, {{experience.location.country}}
                    </span>
                </div>
                <div class="product-icons d-lg-block d-none">
                    <span (click)="clickFavorite($event, experience['@id'])"
                        [ngClass]="takerExperiences.includes(experience['@id']) ? 'vi-heart-fill' : 'vi-heart'"
                        class="mr-3 pointer"></span>
                    <span (click)="shareExperience(experience)" class="vi-plane pointer"></span>
                </div>
            </div>
        </div>
    </div>
    <div class="rounded product-images row mt-4 mb-lg-5 w-100 mx-0">
        <div class="h-lg-100 col-md-6 col-sm-12 pl-0 position-relative">
            <img class="h-100 img-fluid" style="cursor: pointer;"
                onError="this.src='https://venntur.com/assets/image-not-available-placeholder.png'"
                [src]="getUrl(experience.civitatisId, (experience.mediaExperiences.length > 0 ? experience.mediaExperiences[0].contentUrl : ''))"
                alt="Imagen de la empresa" (click)="openPhotosModal(photosContent,{class: 'photosModal'})">
            <span *ngIf="experience.promotionType" class="position-absolute pink-button">{{experience.promotionType}}</span>
        </div>
        <div class="w-100 d-block d-md-none">
            <div class="d-flex justify-content-between align-items-center" style="margin-top: 21px;">
                <!-- Removed button here -->
                <div class="product-icons d-block d-lg-none">
                    <span (click)="clickFavorite($event, experience['@id'])"
                        [ngClass]="takerExperiences.includes(experience['@id']) ? 'vi-heart-fill' : 'vi-heart'"
                        class="mr-3 pointer"></span>
                    <span (click)="shareExperience(experience)" class="vi-plane pointer"></span>
                </div>
            </div>
            <div class="select-anchor">
                <select class="w-100" name="select"
                    (change)="scrollToElement($event.target.value.toString()); activeTag = 'description'">
                    <option value="section-1">{{ 'Descripción' | translate }}</option>
                    <option value="section-2">{{ 'Qué incluye' | translate }}</option>
                    <option value="section-3">{{ 'Excluido' | translate }}</option>
                    <option value="section-4">{{ 'Qué debes saber' | translate }}</option>
                    <option value="section-5">{{ 'Qué debes llevar' | translate }}</option>
                </select>
            </div>
        </div>
        <div class="col-md-6 d-md-block d-none pr-0">
            <div class="row m-0 w-100">
                <div class="col-md-6 pl-0" style="margin-bottom: 4px;">
                    <img class="img-fluid custom-image" style="cursor: pointer;"
                        onError="this.src='https://venntur.com/assets/image-not-available-placeholder.png'"
                        [src]="getUrl(experience.civitatisId, (experience.mediaExperiences.length > 1 ? experience.mediaExperiences[1].contentUrl : ''))"
                        alt="Imagen de la experiencia" (click)="openPhotosModal(photosContent,{class: 'photosModal'})">
                </div>
                <div class="col-md-6 pr-0">
                    <img class="img-fluid custom-image" style="cursor: pointer;"
                        onError="this.src='https://venntur.com/assets/image-not-available-placeholder.png'"
                        [src]="getUrl(experience.civitatisId, (experience.mediaExperiences.length > 2 ? experience.mediaExperiences[2].contentUrl : ''))"
                        alt="Imagen de la experiencia" style="border-radius: 0px 20px 0px 0px;" (click)="openPhotosModal(photosContent,{class: 'photosModal'})">
                </div>
                <div class="col-md-6 pl-0">
                    <img class="img-fluid custom-image" style="cursor: pointer;"
                        onError="this.src='https://venntur.com/assets/image-not-available-placeholder.png'"
                        [src]="getUrl(experience.civitatisId, (experience.mediaExperiences.length > 3 ? experience.mediaExperiences[3].contentUrl : ''))"
                        alt="Imagen de la experiencia" (click)="openPhotosModal(photosContent,{class: 'photosModal'})">
                </div>
                <div class="col-md-6 pr-0">
                    <img class="img-fluid custom-image" style="cursor: pointer;"
                        onError="this.src='https://venntur.com/assets/image-not-available-placeholder.png'"
                        [src]="getUrl(experience.civitatisId, (experience.mediaExperiences.length > 4 ? experience.mediaExperiences[4].contentUrl : ''))"
                        alt="Imagen de la experiencia" style="border-radius: 0px 0px 20px 0px;" (click)="openPhotosModal(photosContent,{class: 'photosModal'})">
                </div>
            </div>
        </div>
    </div>
    
    <div class="row pt-md-0">
        <div class="col-lg-8 col-12 col pt-3 mb-5">
            <div class="product-details">
                <div class="product-anchors d-md-flex d-none">
                    <a [ngClass]="{'active' : activeTag == 'description'}"
                        (click)="scrollToElement('section-1'); activeTag = 'description'"><strong>{{ 'Descripción' | translate }}</strong></a>
                    <a *ngIf="experience.includes && experience.includes.length > 0"
                        [ngClass]="{'active' : activeTag == 'includes'}"
                        (click)="scrollToElement('section-2'); activeTag = 'includes'"><strong>{{ 'Qué incluye' | translate }}</strong></a>
                    <a *ngIf="experience.notIncludes && experience.notIncludes.length > 0"
                        [ngClass]="{'active' : activeTag == 'not-includes'}"
                        (click)="scrollToElement('section-3'); activeTag = 'not-includes'"><strong>{{ 'Excluido' | translate }}</strong></a>
                    <a *ngIf="experience.whyUs" [ngClass]="{'active' : activeTag == 'what-to-know'}"
                        (click)="scrollToElement('section-4'); activeTag = 'what-to-know'"><strong>{{ 'Qué debes saber' | translate }}</strong></a>
                    <a *ngIf="experience.carryInfo" [ngClass]="{'active' : activeTag == 'what-to-bring'}"
                        (click)="scrollToElement('section-5'); activeTag = 'what-to-bring'"><strong>{{ 'Qué debes llevar' | translate }}</strong></a>
                </div>
                <div class="d-flex rounded p-3 product-details">
                    <div class="pr-3">
                        <span class="vi-clock pr-1"></span>
                        <span>
                            {{convertTimeToCustomFormat(experience.duration)}}
                        </span>
                        <!-- <span *ngIf="experience.duration == null">
                            Sin datos
                        </span> -->
                    </div>
                    <div *ngIf="experience.weekDays && experience.weekDays.length > 0" class="pr-3">
                        <span class="pr-2 d-none d-md-inline">|</span>
                        <span class="vi-calendar pr-1"></span>
                        <span *ngFor="let day of experience.weekDays; let last = last">
                            {{ week[day] | translate }}<ng-container *ngIf="!last">,</ng-container>
                        </span>
                        <!-- <span *ngIf="experience.weekDays?.length == 0">
                            Sin datos
                        </span> -->
                    </div>
                    <div>
                        <span class="pr-2 d-none d-md-inline">|</span>
                        <span class="vi-bubble pr-1"></span>
                        <span *ngFor="let language of experience.languages; let last = last">
                            {{language.name}}<ng-container *ngIf="!last">,</ng-container>
                        </span>
                        <span *ngIf="experience.languages.length == 0">
                            Sin datos
                        </span>
                    </div>
                </div>
                <div id="section-1" class="includes">
                    <span [innerHTML]="experience.descriptionExtended">
                    </span>
                    <span *ngIf="experience.description == ''">
                        Sin datos
                    </span>
                </div>
                <div class="row m-0 p-0">
                    <div class="col-lg-6 col pl-0" *ngIf="experience.includes && experience.includes.length != 0">
                        <div id="section-2" class="title">
                            <span>
                                {{ 'Qué incluye' | translate }}
                            </span>
                        </div>
                        <ul class="bullets-includes">
                            <li class="pb-3" *ngFor="let includes of experience.includes; let last = last">
                                {{includes}}
                            </li>
                            <!-- <li *ngIf="experience.includes?.length == 0">
                                Sin datos
                            </li> -->
                        </ul>
                    </div>
                    <div class="col-lg-6 col-12 pl-0 pl-lg-3 pr-0"
                        *ngIf="experience.notIncludes && experience.notIncludes.length != 0">
                        <div id="section-3" class="title">
                            <span>
                                {{ 'Excluido' | translate }}
                            </span>
                        </div>
                        <ul class="bullets-not-includes">
                            <li class="pb-3" *ngFor="let notIncludes of experience.notIncludes; let last = last">
                                {{notIncludes}}
                            </li>
                            <!-- <li *ngIf="experience.notIncludes?.length == 0">
                                Sin datos
                            </li> -->
                        </ul>
                    </div>
                </div>
                <div id="section-4" class="py-4" *ngIf="experience.whyUs">
                    <div class="title">
                        <span>
                            {{ 'Qué debes saber' | translate }}
                        </span>
                    </div>
                    <span [innerHTML]="experience.whyUs">
                    </span>
                    <!-- <span *ngIf="experience.whyUs == null">
                        Sin datos
                    </span> -->
                </div>
                <div id="section-5" class="py-4" *ngIf="experience.carryInfo">
                    <div class="title">
                        <span>
                            {{'Qué debes llevar' | translate }}
                        </span>
                    </div>
                    <!-- <span>
                        {{experience.carryInfo}}
                    </span> -->
                    <ul class="bullets-not-includes">
                        <li class="pb-3" *ngFor="let carryInfo of experience.carryInfo; let last = last">
                            {{carryInfo}}
                        </li>
                        <!-- <li *ngIf="experience.notIncludes?.length == 0">
                            Sin datos
                        </li> -->
                    </ul>
                    <!-- <span *ngIf="experience.carryInfo == null">
                        Sin datos
                    </span> -->
                </div>
                <!-- <div id="TA_cdsratingsonlywide815" class="TA_cdsratingsonlywide"><ul id="FMiDr6" class="TA_links p92BQ7"><li id="EqNTmOQ" class="BupNoTTHS"><a target="_blank" href="https://www.tripadvisor.com/Attraction_Review-g187471-d19512935-Reviews-Camello_Safari_Duna_Oasis-Gran_Canaria_Canary_Islands.html"><img src="https://www.tripadvisor.com/img/cdsi/img2/branding/v2/Tripadvisor_lockup_horizontal_secondary_registered-18034-2.svg" alt="TripAdvisor"/></a></li></ul></div>                <div *ngIf="false" id="section-6" class="py-4 tripadvisor-widget" style="min-height: 680px;">
                    <div class="d-flex justify-content-between mb-3">
                        <div class="d-flex flex-column">
                            <div class="d-flex mb-2">
                                <img class="mr-2" src="../../../../assets/images/tripadvisor.jpg"
                                    alt="Logo TripAdvisor">
                                <span style="font-size: 20px;">Rating</span>
                            </div>
                            <div class="d-flex align-items-center">
                                <span class="mr-2">3.0</span>
                                <div *ngFor="let ball of [].constructor(3)">
                                    <span class="ball"></span>
                                </div>
                                <div *ngFor="let emptyBall of [].constructor(2)">
                                    <span class="empty-ball"></span>
                                </div>
                                <span class="ml-2" style="font-size: 12px;">102 reviews</span>
                            </div>
                        </div>
                        <div>
                            <button class="tripadvisor-button">{{'Deja tu reseña' | translate}}</button>
                        </div>
                    </div>
                    <div *ngFor="let opinion of tripAdvisorOpinions" class="d-flex my-3">
                        <img class="userImage mr-3" [src]="opinion.image" alt="Imagen de la reseña">
                        <div>
                            <div class="d-flex flex-column mb-3">
                                <strong style="font-size: 14px;">
                                    {{opinion.username}}
                                </strong>
                                <span style="font-size: 12px;">
                                    {{opinion.date}}
                                </span>
                            </div>
                            <div class="d-flex flex-column">
                                <strong>
                                    {{opinion.title}}
                                </strong>
                                <span>
                                    {{opinion.review}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div> -->
                <app-review-form (formSent)="loadExperience()" [receivedData]="experience['@id']"></app-review-form>
                <app-reviews [receivedData]="reviews"></app-reviews>
            </div>
        </div>
        <div class="col-lg-4 col-12 pt-lg-0 pt-4 mb-5">
            <div class="booking-widget text-center">
                <div>
                    <strong>
                        {{ 'Solicitud de Reserva' | translate }}
                    </strong>
                </div>
                <div class="card shadow rounded p-3" style="min-height: 680px;margin-bottom: 50px;">
                    <div class="d-flex flex-column justify-content-center">
                        <div *ngIf="turitopId" id="turitop-calendar" class="load-turitop mb-5"
                            [attr.data-service]="turitopId" data-lang="es" data-embed="box"></div>
                        <div id="fareharbor-calendar" class="fareharbor-calendar-wrap"></div>
                        <div *ngIf="false" class="bokunWidget"
                            [attr.data-src]="'https://widgets.bokun.io/online-sales/'+(this.experience.bookingEngineWidget ? this.experience.bookingEngineWidget : this.experience.maker.bookingEngineWidget)+'/experience-calendar/'+bokunId+'?isDuda=true&lang=es'">
                        </div>
                        <iframe *ngIf="rezdyId" seamless="" width="auto" height="1000px" frameborder="0" class="rezdy" src="" id="iFrameResizer0" scrolling="no" style="overflow: hidden; height: 828.438px;"></iframe>
                        <template id="don-EDCFD2BC-EE8E-4D29-A680-25474C872D85"></template>
                        <form *ngIf="showDefault" [formGroup]="bookingRequestForm" method="post"
                            (submit)="sendBookingEmail()">
                            <div class="w-auto">
                                <bs-datepicker-inline (bsValueChange)="storeDate($event)" [bsValue]="bsInlineValue"
                                    [bsConfig]="bsConfig"></bs-datepicker-inline>
                            </div>
                            <div class="px-4">
                                <div class="d-flex border-bottom border-top">
                                    <div class="w-50 mr-2">
                                        <div class="my-1 text-left border-right">
                                            <label class="text-left" for="adults">{{ 'Adultos' | translate }}<span
                                                    *ngIf="showError('adults')" class="vi-close ml-3"
                                                    style="color: #dc3545 !important;"></span></label>
                                            <ng-select [(ngModel)]="selectedAdults" name="adults"
                                                formControlName="adults">
                                                <ng-option
                                                    *ngFor="let adultQty of [].constructor(experience.persons);let i = index"
                                                    [value]="i+1">{{i+1}}</ng-option>
                                            </ng-select>
                                        </div>

                                    </div>
                                    <div class="w-50 ml-2">
                                        <div class="my-1 text-left">
                                            <label class="text-left" for="kids">{{ 'Niños' | translate }}<span *ngIf="showError('kids')"
                                                    class="vi-close ml-3"
                                                    style="color: #dc3545 !important;"></span></label>
                                            <ng-select [(ngModel)]="selectedKids" name="kids" formControlName="kids">
                                                <ng-option
                                                    *ngFor="let adultQty of [].constructor(experience.persons);let i = index"
                                                    [value]="i">{{i}}</ng-option>
                                            </ng-select>
                                        </div>

                                    </div>
                                </div>
                                <div class="border-bottom d-flex flex-column pt-1">
                                    <label class="text-left" for="name">{{ 'Nombre' | translate }}</label>
                                    <input formControlName="name" type="text" name="name" placeholder="--">
                                    <small class="text-danger" *ngIf="showError('name')">
                                        {{ 'Debe introducir un campo válido' | translate }}
                                    </small>
                                </div>
                                <div class="border-bottom d-flex flex-column pt-1">
                                    <label class="text-left" for="phone">{{ 'Teléfono' | translate }}</label>
                                    <input formControlName="phone" type="text" name="phone" placeholder="--">
                                    <small class="text-danger" *ngIf="showError('phone')">
                                        {{ 'Debe introducir un campo válido' | translate }}
                                    </small>
                                </div>
                                <div class="border-bottom d-flex flex-column pt-1">
                                    <label class="text-left" for="email">Mail</label>
                                    <input formControlName="email" type="text" name="email" placeholder="--">
                                    <small class="text-danger" *ngIf="showError('email')">
                                        {{ 'Debe introducir un campo válido' | translate }}
                                    </small>
                                </div>
                                <div class="border-bottom d-flex flex-column pt-1 mb-3">
                                    <label class="text-left" for="comments">{{ 'Comentarios' | translate }}</label>
                                    <textarea formControlName="comments" type="textarea" name="comments"
                                        placeholder="--"></textarea>
                                    <small class="text-danger" *ngIf="showError('comments')">
                                        {{ 'Debe introducir un campo válido' | translate }}
                                    </small>
                                </div>
                                <div class="d-flex data-protection mb-3">
                                    <input type="checkbox" formControlName="dataProtection" name="data-protection"
                                        class="mr-2">
                                    <label [ngClass]="showError('dataProtection') ? 'border-bottom-red' : ''"
                                        for="data-protection" class="font-weight-normal">{{'He leído y acepto la' | translate }} <a
                                            target="_blank"
                                            href='https://pro.venntur.com/politica-de-privacidad/'>{{ 'Política de privacidad' | translate }}</a></label>
                                </div>
                                <button type="submit" class="btn btn-primary w-100 mb-3"
                                    [disabled]="bookingRequestSuccess">{{ 'Reservar' | translate }}</button>
                                <div *ngIf="bookingRequestSuccess" class="alert alert-success">
                                    {{ 'Solicitud enviada!' | translate }}
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
            <button [routerLink]="'/' + translate.getDefaultLang() + '/experiencias/' + experience.slug + '/redirigir'" target="_blank" class="btn btn-primary w-100 mb-3" [disabled]="bookingRequestSuccess">{{ 'Reservar en la web' | translate }}</button>
            <!-- <div class="d-flex flex-column text-center mb-3">
                <div class="p-0" style="font-size: 18px;">
                    <strong>
                        {{ '¿Alguna duda?' | translate }}
                    </strong>
                </div>
                <small>
                    {{ 'Estamos aquí para ayudarte' | translate }}
                </small>
            </div> -->
            <div class="maker-info card rounded">
                <div class="d-flex justify-content-between">
                    <span>{{ 'Organizado por' | translate }}</span>
                    <a *ngIf="experience.maker['makerPlan'] && experience.maker['makerPlan']['plan']['shortName'] !== 'Plan Free' && !experience.civitatisId && experience.maker['sheetCompleted']"
                        [routerLink]="'/' + translate.getDefaultLang() +'/maker/' + experience.maker.slug">
                        {{ 'Ver ficha de empresa' | translate }}
                    </a>
                </div>
                <div class="d-flex align-items-center justify-content-between">
                    <a [routerLink]="'/' + translate.getDefaultLang() +'/maker/' + experience.maker.slug">
                        <img class="pl-1" alt="Logo de la empresa"
                            [src]="'https://venntur.com/assets/maker/files/' + (experience.maker.mediaMakers.length > 0 ? experience.maker.mediaMakers[0].contentUrl : '')"
                            height="40">
                    </a>
                    <a [routerLink]="'/' + translate.getDefaultLang() +'/maker/' + experience.maker.slug" class="text-dark font-weight-bold">
                        <span>{{ experience.maker.name }}</span>
                    </a>
                </div>
                <div class="maker-info-footer d-flex justify-content-between m-0 w-100">
                    <a [href]="experience.maker.web" target="_blank" class=" w-100 h-100">
                        <span class="vi-web"></span>
                    </a>
                    <ng-template #tol1Template>{{ customPhoneTooltip }}</ng-template>
                    <span class="d-none d-lg-flex w-100 h-100 pointer" [tooltip]="tol1Template"
                        (click)="changePhoneTooltip(experience.maker.phone)">
                        <span class="vi-mobile"></span>
                    </span>
                    <a [href]="'tel:' + experience.maker.phone" class="d-flex d-lg-none w-100 h-100">
                        <span class="vi-mobile"></span>
                    </a>
            
                    <ng-template #tol2Template>{{ customEmailTooltip }}</ng-template>
                    <span class="d-none d-lg-flex w-100 h-100 pointer" [tooltip]="tol2Template"
                        (click)="changeEmailTooltip(experience.maker.email)">
                        <span class="vi-email"></span>
                    </span>
                    <a [href]="'mailto:' + experience.maker.email + '?Subject=Hola%20Maker'"
                        class="d-flex d-lg-none w-100 h-100">
                        <span class="vi-email"></span>
                    </a>
            
                    <a *ngIf="experience.maker.whatsapp" [href]="'https://wa.me/' + experience.maker.whatsapp"
                        class="pointer d-none d-lg-flex w-100 h-100">
                        <span class="vi-whatsapp"></span>
                    </a>
                    <a *ngIf="experience.maker.whatsapp"
                        [href]="'whatsapp://send?phone=' + experience.maker.whatsapp.replace('+','')"
                        class="d-flex d-lg-none w-100 h-100">
                        <span class="vi-whatsapp"></span>
                    </a>
                </div>
            </div>
            
        </div>
    </div>
</div>

<ng-template #photosContent>
    <div class="modal-body mx-auto d-flex flex-column">
        <carousel [isAnimated]="true" [interval]="false" [showIndicators]="false">
            <div *ngFor="let mediaExperience of experience.mediaExperiences; let i = index">
                <slide>
                    <div class="d-flex justify-content-between modal-body-actions my-3">
                        <span class="mr-4">{{i + 1}}/{{experience.mediaExperiences.length}}</span>
                        <div class="experience-icons">
                            <span (click)="clickFavorite($event, experience['@id'])"
                                [ngClass]="takerExperiences.includes(experience['@id']) ? 'vi-heart-fill' : 'vi-heart'"
                                class="vi-heart mr-3 pointer"></span>
                            <span (click)="shareExperience(experience)" class="vi-plane mr-3 pointer"></span>
                        </div>
                        <button type="button" class="btn btn-lg btn-white btn-close pull-right ml-auto p-1"
                            aria-label="Close" (click)="modalRef.hide()">
                            <span class="vi-close"></span>
                        </button>
                    </div>
                    <img [src]="getUrl(experience.civitatisId, mediaExperience.contentUrl)" class="rounded"
                        style="display: block; width: 100%;"
                        onError="this.src='assets/image-not-available-placeholder.png'">
                </slide>
            </div>
        </carousel>
    </div>
</ng-template>

<div *ngIf="isAlertShown" [config]="{ show: true }" (onHidden)="onHidden()" bsModal #autoShownModal="bs-modal"
    class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-auto-name">
    <div class="modal-dialog">
        <div class="modal-content">
            <button type="button" class="btn btn-sm btn-light btn-close pull-right ml-auto" aria-label="Close"
                (click)="hideModal()">
                <span class="vi-close"></span>
            </button>
            <div class="modal-body text-center ">
                <p><span class="vi-info text-primary h3"></span></p>
                <p [innerHTML]="'RegFavs' | translate"></p>
                <app-login></app-login>
            </div>
        </div>
    </div>
</div>